<template>
  <div>
    <v-color-picker
      v-model="item.Value"
      mode="hexa"
      :disabled="
        $helpers.itemNeedsDisable(item.ReadOnly, needsDisable, editItemAdmin)
      "
      dot-size="25"
      swatches-max-height="200"
    ></v-color-picker>
    <v-row no-gutters v-if="$store.getters.editMode">
      <v-col cols="12">
        <hr />
      </v-col>
      <v-col
        cols="12"
        :lg="
          item.colWidth < 7
            ? item.colWidth < 5
              ? 12
              : 4
            : item.colWidth < 10
            ? 4
            : 2
        "
      >
        <v-layout column>
          <v-text-field
            v-model.number="item.colWidth"
            hide-details="auto"
            type="number"
            max="12"
            min="1"
            :label="$t('Column width')"
            class="mt-4"
            outlined
            dense
          ></v-text-field>
          <v-switch
            flat
            :ripple="false"
            v-model="item.ReadOnly"
            :label="$t('Read-only')"
            hide-details="auto"
          ></v-switch>
          <v-switch
            flat
            :ripple="false"
            v-model="item.RemoveCard"
            hide-details="auto"
            class="float-left mr-3"
            :label="$t('Remove frame')"
          ></v-switch>
        </v-layout>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: [Array, Object],
    },
    needsDisable: {
      type: Boolean,
    },
    editItemAdmin: {
      type: Boolean,
    },
  },
};
</script>

<style>
</style>